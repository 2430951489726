import { convertAttributesInElement } from '@storyblok/react'
import {
  type StoryblokRichTextNode,
  useStoryblokRichText,
} from '@storyblok/react/rsc'
import { type ReactElement } from 'react'

import { type StoryblokRichtextType } from './model/storyblokTypes.generated'

export const useCmsRichTextRender = () => {
  const { render } = useStoryblokRichText({})

  return (
    content: StoryblokRichtextType,
  ): ReturnType<typeof convertAttributesInElement> =>
    convertAttributesInElement(
      render(content as StoryblokRichTextNode) as ReactElement,
    )
}
